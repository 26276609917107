/* eslint no-console:0 */

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("@rails/ujs").start()
// require("@rails/activestorage").start()

require("../newpicture/uploader.js");
//console.log("TNP::App loaded");
