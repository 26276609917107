import { DirectUpload } from "activestorage";

document.addEventListener("DOMContentLoaded", function () {
  const progressBarEl = document.querySelector(".progress-bar-element");

  let inputs = Array.from(document.querySelectorAll(".upload-field"));
  inputs.forEach(function (input) {
    input.addEventListener("change", (event) => {
      Array.from(input.files).forEach((file) => uploadFile(file, input));
      input.value = null;
    });
  });

  const uploadFile = (file, element) => {
    const url = element.dataset.directUploadUrl;
    const upload = new Uploader(file, url, element);
    upload.start();
  };
});

class Uploader {
  constructor(file, url, element) {
    this.file = file;
    this.url = url;
    this.element = element;
    this.upload = new DirectUpload(this.file, this.url, this);
  }

  showUploadProgress() {
    document
      .querySelector("#overlay-uploading")
      .classList.add("overlay-uploading-show");
    document
      .querySelector("#overlay-uploading .progress-wrapper")
      .classList.remove("hide");
  }

  hideUploadProgress() {
    document
      .querySelector("#overlay-uploading")
      .classList.remove("overlay-uploading-show");
  }

  start() {
    this.showUploadProgress();
    this.upload.create((error, blob) => {
      if (error) {
        this.hideUploadProgress();
      } else {
        const form = this.element.form;
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.element.name;
        this.element.remove();
        form.appendChild(hiddenField);
        form.submit();
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("start", (e) => console.log("start"));
    request.upload.addEventListener("progress", (e) =>
      this.uploadProgressUpdate(e)
    );
  }

  uploadProgressUpdate(e) {
    const percentage = Math.round((e.loaded * 100) / e.total) + "%";
    document.querySelector(".progress-bar-element").style.width = percentage;
    document.querySelector(".progress-bar-text").innerHTML = percentage;
  }
}

//console.log("TNP::Uploader ready");
